.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
  transform-origin: 50px 50px;
}

.background {
  position: fixed;
  left: -15px;
  right: -15px;
  top: -15px;
  bottom: -15px;
  z-index: -1;
  background-size: cover;
  
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.title {
  text-align: center;
  max-width: 450px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.theme-dark {
  color: bisque;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 5s cubic-bezier(0.645, 0.045, 0.355, 1); */
    animation: App-logo-spin infinite 4s cubic-bezier(0.86, 0, 0.07, 1);
    animation-delay: 5s;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
